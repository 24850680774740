import { Controller } from '@hotwired/stimulus';
import { MutexFetcher } from 'api/mutex_fetcher';
import Routes from 'common/rails_routes';
class UserAnnouncementsController extends Controller {
}
class default_1 extends Controller {
    connect() {
        this.fetcher = new MutexFetcher();
    }
    create() {
        this.fetcher.call({
            url: Routes.user_announcement_path(),
            method: 'POST',
            body: { id: this.idValue },
            callback: () => {
                this.selfTarget.classList.add('d-none');
            }
        });
    }
}
default_1.targets = ['self', 'id'];
default_1.values = { id: Number };
export default default_1;
