import { Controller } from '@hotwired/stimulus';
import { RailsFetcher } from 'api/rails_fetcher';
import Routes from 'common/rails_routes';
class ContentVisibilityController extends Controller {
}
class default_1 extends Controller {
    connect() {
        const level = this.getInitialVisibility();
        this.setLevel(level);
        this.modalTarget.addEventListener('show.bs.modal', () => {
            this.getPartialHtml(0);
        });
    }
    setLevel(level) {
        const targets = this.groupByVisbilityLevel(this.levelTargets, level);
        targets.matched[0].classList.remove('d-none');
        targets.others.forEach(element => {
            element.classList.add('d-none');
        });
    }
    getFriendsPage(e) {
        const target = e.currentTarget;
        const page = parseInt(target.dataset.page || '0', 10);
        this.getPartialHtml(page);
    }
    getInitialVisibility() {
        const level = this.initialLevelValue;
        switch (level) {
            case 'friends':
            case 'savanna':
            case 'internet':
                return level;
            default:
                return 'friends';
        }
    }
    async getPartialHtml(page) {
        const response = await RailsFetcher.call({
            url: Routes.search_api_content_visible_friends_path({ page }),
            method: 'POST',
            body: { page }
        });
        if (!response.ok)
            throw new Error("response isn't ok");
        const data = await response.text();
        this.friendsTarget.innerHTML = data;
    }
    groupByVisbilityLevel(list, level) {
        const result = {
            matched: [],
            others: []
        };
        list.forEach((elem) => {
            if (elem.dataset.level === level) {
                result.matched.push(elem);
            }
            else {
                result.others.push(elem);
            }
        });
        return result;
    }
}
default_1.values = { initialLevel: String };
default_1.targets = ['level', 'friends', 'modal'];
export default default_1;
