import { Controller } from '@hotwired/stimulus';
import { PreviewClient } from 'api/preview_client';
import { FileAttacher, disablingSubmitButtonOnUpload } from 'api/file_attacher';
import dragDrop from 'drag-drop';
import { Confirmable } from 'mixins/confirmable';
class ConditionsController extends Controller {
}
class default_1 extends Confirmable(Controller) {
    connect() {
        this.initConfirmableForms([this.formTarget.id]);
        if (this.hasPreviewTarget) {
            this.client = new PreviewClient({ previewTarget: this.previewTarget, bodyTarget: this.bodyTarget });
        }
        if (this.hasBodyTarget) {
            this.fileAttacher = new FileAttacher({
                bodyTarget: this.bodyTarget,
                callback: disablingSubmitButtonOnUpload(this.submitTarget)
            });
            dragDrop(this.bodyTarget, (files) => {
                this.fileAttacher.uploadFiles(files, window);
            });
        }
    }
    updatePreview() {
        this.client.call();
    }
    clickFileUploadButton(e) {
        e.preventDefault();
        this.filesTarget.click();
    }
    changeFiles() {
        const files = this.filesTarget.files;
        if (files === null) {
            throw new Error('No files found');
        }
        this.fileAttacher.uploadFiles(Array.from(files), window);
    }
    pasteClipboardData(e) {
        if (e.clipboardData.types.indexOf('Files') > -1) {
            this.fileAttacher.uploadFiles(Array.from(e.clipboardData.files), window);
            e.preventDefault();
        }
    }
}
default_1.targets = ['preview', 'body', 'files', 'submit', 'form'];
export default default_1;
