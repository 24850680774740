import { Controller } from '@hotwired/stimulus';
import { PreviewClient } from 'api/preview_client';
import { FileAttacher, disablingSubmitButtonOnUpload } from 'api/file_attacher';
import dragDrop from 'drag-drop';
import Routes from 'common/rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
import { Confirmable, ConfirmableEvent } from 'mixins/confirmable';
class BoardCommentEditorController extends Controller {
}
class default_1 extends Confirmable(Controller) {
    connect() {
        this.initConfirmableForms([this.formTarget.id]);
        this.currentBody = this.bodyTarget.value;
        this.client = new PreviewClient({ previewTarget: this.previewTarget, bodyTarget: this.bodyTarget });
        // NOTE: data-targetを指定するとBootstrapのtabの挙動が怪しくなる為、ここでは使用していない
        const formTab = this.selfTarget.querySelector('.js-form-tab');
        if (formTab === null) {
            throw new Error('No URL found');
        }
        this.formTab = formTab;
        const previewTab = this.selfTarget.querySelector('.js-preview-tab');
        if (previewTab === null) {
            throw new Error('No previewTab found');
        }
        this.previewTab = previewTab;
        this.fileAttacher = new FileAttacher({
            bodyTarget: this.bodyTarget,
            callback: disablingSubmitButtonOnUpload(this.submitTarget)
        });
        dragDrop(this.bodyTarget, (files) => {
            this.fileAttacher.uploadFiles(files, window);
        });
        this.uploadButtonTarget.disabled = false;
        if (this.selfTarget.dataset.commentId === undefined) {
            this.cancelButtonTarget.style.display = 'none';
            this.cancelButtonPreviewTarget.style.display = 'none';
        }
        this.updateCheering(this.selfTarget.dataset.cheering === 'true');
    }
    pasteClipboardData(e) {
        if (e.clipboardData.types.indexOf('Files') > -1) {
            this.fileAttacher.uploadFiles(Array.from(e.clipboardData.files), window);
            e.preventDefault();
        }
    }
    focusPreview() {
        this.updatePreview();
        this.previewTab.focus();
    }
    switchTab(e) {
        if (e.code === 'KeyP' && e.shiftKey && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            if (this.formTab.classList.contains('active')) {
                this.updatePreview();
                this.previewTab.click();
                this.previewTab.focus();
            }
            else {
                this.formTab.click();
                this.formTab.addEventListener('shown.bs.tab', () => {
                    this.bodyTarget.focus();
                });
            }
        }
    }
    clickFileUploadButton(e) {
        e.preventDefault();
        this.filesTarget.click();
    }
    changeFiles() {
        const files = this.filesTarget.files;
        if (files === null) {
            throw new Error('No files found');
        }
        this.fileAttacher.uploadFiles(Array.from(files), window);
    }
    toggle() {
        var _a;
        if ((_a = this.selfTarget.querySelector('.comment-form__comment')) === null || _a === void 0 ? void 0 : _a.classList.contains('d-none')) {
            this.close();
        }
        else {
            this.open();
        }
    }
    open() {
        var _a, _b;
        // NOTE: _comment_form.html.haml で新規作成時・編集時のどちらかでしか
        //       利用されない要素はtargetではなくクラス指定にしている
        (_a = this.selfTarget.querySelector('.comment-form__comment')) === null || _a === void 0 ? void 0 : _a.classList.add('d-none');
        (_b = this.selfTarget.querySelector('.js-comment-editor')) === null || _b === void 0 ? void 0 : _b.classList.remove('d-none');
        this.editIconTarget.classList.add('comment-form__editing');
    }
    close() {
        var _a, _b, _c;
        (_a = this.selfTarget.querySelector('form')) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new Event(ConfirmableEvent.reset));
        // NOTE: _comment_form.html.haml で新規作成時・編集時のどちらかでしか
        //       利用されない要素はtargetではなくクラス指定にしている
        (_b = this.selfTarget.querySelector('.comment-form__comment')) === null || _b === void 0 ? void 0 : _b.classList.remove('d-none');
        (_c = this.selfTarget.querySelector('.js-comment-editor')) === null || _c === void 0 ? void 0 : _c.classList.add('d-none');
        this.editIconTarget.classList.remove('comment-form__editing');
        this.bodyTarget.value = this.currentBody;
    }
    update(event) {
        var _a;
        if (this.selfTarget.dataset.commentId === undefined) {
            return;
        }
        if (!this.bodyTarget.checkValidity()) {
            return;
        }
        event.preventDefault();
        this.loaderTarget.classList.remove('d-none');
        // NOTE: _comment_form.html.haml で新規作成時・編集時のどちらかでしか
        //       利用されない要素はtargetではなくクラス指定にしている
        (_a = this.selfTarget.querySelector('.js-comment-editor')) === null || _a === void 0 ? void 0 : _a.classList.add('d-none');
        const payload = {
            board_comment: {
                body: this.bodyTarget.value
            }
        };
        const boardId = this.selfTarget.dataset.boardId;
        if (boardId === undefined) {
            throw new Error('No boardId found');
        }
        const commentId = this.selfTarget.dataset.commentId;
        if (commentId === undefined) {
            throw new Error('No commentId found');
        }
        // TODO: エラー（例：コメントを空で送った場合）が帰ってきた場合のハンドリングを入れる
        RailsFetcher.call({
            url: Routes.board_comment_path(boardId, commentId),
            method: 'PATCH',
            body: payload
        }).then(response => response.json())
            .then(json => {
            this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
            const markdown = json.markdown;
            const comment = json.comment;
            this.commentTarget.innerHTML = markdown;
            this.commentTarget.classList.remove('d-none');
            this.loaderTarget.classList.add('d-none');
            this.editIconTarget.classList.remove('comment-form__editing');
            this.currentBody = this.bodyTarget.value;
            this.selfTarget.dataset.cheering = comment.cheering.toString();
        });
    }
    delete() {
        if (!window.confirm('本当に削除しますか？')) {
            return;
        }
        const boardId = this.selfTarget.dataset.boardId;
        if (boardId === undefined) {
            throw new Error('No boardId found');
        }
        const commentId = this.selfTarget.dataset.commentId;
        if (commentId === undefined) {
            throw new Error('No commentId found');
        }
        RailsFetcher.call({
            url: Routes.board_comment_path(boardId, commentId),
            method: 'DELETE'
        }).then(response => {
            if (response.ok) {
                this.selfTarget.remove();
            }
        });
    }
    updatePreview() {
        this.previewTarget.innerHTML = '';
        this.client.call(100);
    }
    updateCheering(state) {
        var _a, _b;
        if (!this.hasCheeringMarkTarget)
            return;
        // TODO: stateに応じて「応援コメント」を表示・非表示を切り替える
        if (state) {
            (_a = this.cheeringMarkTarget) === null || _a === void 0 ? void 0 : _a.classList.remove('d-none');
        }
        else {
            (_b = this.cheeringMarkTarget) === null || _b === void 0 ? void 0 : _b.classList.add('d-none');
        }
    }
}
default_1.targets = ['self', 'preview', 'body', 'files', 'submit',
    'uploadButton', 'cancelButton', 'cancelButtonPreview', 'comment',
    'editIcon', 'form', 'loader', 'cheering', 'cheeringMark', 'cheeringNotice'];
export default default_1;
