import { Controller } from '@hotwired/stimulus';
import CharCounter from '../common/char_counter';
import RailsExportedConfig from 'common/rails_exported_config';
const ValidationRule = RailsExportedConfig.validation;
class ProfileController extends Controller {
}
class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.counters = new Map();
    }
    connect() {
        this.counters.set('name', CharCounter(this.nameTarget, this.nameCountTarget, ValidationRule.user.name.length.maximum));
        this.counters.set('occupation', CharCounter(this.occupationTarget, this.occupationCountTarget, ValidationRule.user.occupation.length.maximum));
        this.nameTarget.addEventListener('input', this.getEventListner('name'));
        this.occupationTarget.addEventListener('input', this.getEventListner('occupation'));
    }
    disconnect() {
        this.nameTarget.removeEventListener('input', this.getEventListner('name'));
        this.occupationTarget.removeEventListener('input', this.getEventListner('occupation'));
    }
    getEventListner(name) {
        const listner = this.counters.get(name);
        if (listner === undefined) {
            throw new Error(`Undefined event handler for ${name}`);
        }
        return listner;
    }
}
default_1.targets = ['name', 'nameCount', 'occupation', 'occupationCount'];
export default default_1;
