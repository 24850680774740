import { Controller } from '@hotwired/stimulus';
class DailyReportCommentsController extends Controller {
}
class default_1 extends Controller {
    connect() {
        // NOTE: _comment_form.html.haml で新規作成時・編集時のどちらかでしか
        //       利用されない要素はtargetではなくクラス指定にしている
        this.newCommentForm = this.newCommentTarget.querySelector('.js-comment-editor__form');
        this.newCommentBody = this.newCommentTarget.querySelector('.comment-form__textfield');
    }
    quoteSelectedText(e) {
        if (e.code === 'KeyR') {
            this.addSelectionToCommentBody(e);
        }
    }
    addSelectionToCommentBody(e) {
        var _a;
        const selected = ((_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) || '';
        if (selected === '' || document.activeElement === this.newCommentBody || !this.newCommentForm.classList.contains('active')) {
            return;
        }
        e.preventDefault();
        const insert = selected.split('\n').map((line) => {
            return `> ${line}`;
        }).join('\n');
        this.newCommentBody.value = this.newCommentBody.value + insert;
        this.newCommentBody.focus();
    }
}
default_1.targets = ['newComment'];
export default default_1;
