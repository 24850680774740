import { Controller } from '@hotwired/stimulus';
class OnesignalsController extends Controller {
}
class default_1 extends Controller {
    initialize() {
        window.OneSignal.push(() => {
            window.OneSignal.on('subscriptionChange', (isSubscribed) => {
                const button = this.buttonTarget;
                if (isSubscribed) {
                    button.textContent = 'ブラウザ通知受信設定済み';
                    button.disabled = true;
                }
                else {
                    button.textContent = 'ブラウザ通知を受信する';
                    button.disabled = false;
                }
            });
        });
    }
    connect() {
        this.changeButtonLabel();
    }
    register(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.buttonTarget.disabled) {
            return;
        }
        window.OneSignal.push(() => window.OneSignal.registerForPushNotifications());
    }
    changeButtonLabel() {
        const button = this.buttonTarget;
        window.OneSignal.push(() => {
            const isPushSupported = window.OneSignal.isPushNotificationsSupported();
            if (!isPushSupported) {
                button.textContent = 'ブラウザ通知非対応ブラウザ';
                button.disabled = true;
                return;
            }
            window.OneSignal.getNotificationPermission((permission) => {
                if (permission === 'granted') {
                    button.textContent = 'ブラウザ通知受信設定済み';
                    button.disabled = true;
                }
                else if (permission === 'denied') {
                    button.textContent = 'ブラウザ通知受信設定済み';
                    button.disabled = true;
                }
                else { // default
                    button.textContent = 'ブラウザ通知を受信する';
                    button.disabled = false;
                }
            });
        });
    }
}
default_1.targets = ['button'];
export default default_1;
