import { Modal } from 'bootstrap';
import Routes from './rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
export function showPublicDailyReportNotice(onClickHandler, onHideHandler) {
    const dialog = document.querySelector('#noticeModal');
    if (dialog === null) {
        throw new Error('No dialog found');
    }
    const acceptButton = document.getElementById('acceptConfirmation');
    if (acceptButton === null) {
        throw new Error('No accpetButton found');
    }
    const cancelButton = document.getElementById('cancelConfirmation');
    if (cancelButton === null) {
        throw new Error('No accpetButton found');
    }
    let accepted = false;
    const modal = new Modal(dialog, {
        backdrop: true,
        keyboard: false,
        focus: true
    });
    acceptButton.addEventListener('click', () => {
        accepted = true;
        RailsFetcher.call({
            url: Routes.consent_path({ format: 'json' }),
            method: 'PUT',
            body: { consent: { public_daily_report_confirmed: true } }
        }).then(response => {
            onClickHandler(response);
        }).then(() => {
            modal.hide();
        });
    });
    cancelButton.addEventListener('click', () => {
        modal.hide();
    });
    dialog.addEventListener('hide.bs.modal', () => {
        if (accepted)
            return;
        onHideHandler();
    });
    modal.show();
}
