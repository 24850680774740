import { Textcomplete } from '@textcomplete/core';
import { TextareaEditor } from '@textcomplete/textarea';
import RailsExportedConfig from 'common/rails_exported_config';
const emojies = RailsExportedConfig.emoji;
const emojiNames = Object.keys(emojies);
export const applyEmojiAutoComplete = (textareaElements, list) => {
    for (const el of Array.from(textareaElements)) {
        const editor = new TextareaEditor(el);
        const textcomplete = new Textcomplete(editor, [{
                match: /\B:([\w+]+)$/,
                search: function (term, callback) {
                    console.debug({ term });
                    callback(emojiNames.filter(emoji => { return emoji.startsWith(term); }));
                },
                template: function (key) {
                    const emoji = emojies[key];
                    if (emoji.custom) {
                        return `<img src="/images/emoji/${emoji.filename}" width="20" height="20"> ${key}`;
                    }
                    else {
                        return `${emoji.raw} ${key}`;
                    }
                },
                replace: function (value) {
                    return ':' + value + ': ';
                }
            }]);
        list.push(textcomplete);
    }
};
