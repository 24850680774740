import Routes from 'common/rails_routes';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { RailsFetcher } from 'api/rails_fetcher';
export class FileAttacher {
    constructor({ bodyTarget, callback }) {
        this.bodyTarget = bodyTarget;
        this.callback = callback || {};
    }
    uploadFiles(files, window) {
        this.currentPosition = this.bodyTarget.selectionStart || 0;
        const uppy = this.fileUploader();
        uppy.on('file-added', (file) => {
            this.showUploadingMessage(file.name);
        });
        uppy.on('upload-success', async (file) => {
            if (file === undefined)
                return;
            await this.saveUploadedFile(file).then((urlOrMessage) => {
                if (/^https?:/.test(urlOrMessage)) {
                    this.addFileToTextarea(urlOrMessage, file.name);
                }
                else {
                    this.removeUploadingMessage(file.name);
                    alert(`${file.name}:${urlOrMessage}`);
                }
            });
        });
        uppy.on('upload-error', (_file, error) => {
            window.alert(error);
        });
        files.forEach((file) => {
            try {
                uppy.addFile({
                    data: file,
                    type: file.type,
                    name: file.name
                });
            }
            catch (error) {
                window.alert(error);
            }
        });
        if (this.callback.onStart)
            this.callback.onStart();
        uppy.upload().then(() => {
            if (this.callback.onEnd)
                this.callback.onEnd();
        });
    }
    fileUploader() {
        const uppy = new Uppy({
            autoProceed: false,
            restrictions: {
                maxFileSize: 10 * 1024 * 1024,
                allowedFileTypes: ['image/jpeg', 'image/png', 'image/webp', 'image/gif'],
                maxNumberOfFiles: 5
            }
        });
        uppy.use(AwsS3, {
            companionUrl: '/' // will call Shrine's presign endpoint mounted on `/s3/params`
        });
        return uppy;
    }
    uploadedFileData(file) {
        const matched = file.meta.key.match(/^cache\/(.+)/);
        if (matched === null || matched[1] === null) {
            throw new Error('No matched key');
        }
        const fileData = {
            id: matched[1],
            storage: 'cache',
            metadata: {
                size: file.size,
                filename: file.name,
                mime_type: file.type
            }
        };
        return JSON.stringify(fileData);
    }
    addFileToTextarea(fileURL, filename) {
        const messageToReplace = this.messageForUploading(filename);
        const pos = this.bodyTarget.value.indexOf(messageToReplace);
        const insert = `![${filename}](${fileURL})\n`;
        this.replaceUploadingMessage(pos, insert, messageToReplace.length);
    }
    showUploadingMessage(filename) {
        const message = this.messageForUploading(filename);
        this.replaceUploadingMessage(this.currentPosition, message);
        this.currentPosition += message.length;
    }
    removeUploadingMessage(filename) {
        const message = this.messageForUploading(filename);
        this.bodyTarget.value = this.bodyTarget.value.replace(message, '');
    }
    replaceUploadingMessage(position, textToInsert, offset = 0) {
        const before = this.bodyTarget.value.substr(0, position);
        const after = this.bodyTarget.value.substr(position + offset, this.bodyTarget.value.length);
        this.bodyTarget.value = before + textToInsert + after;
        this.bodyTarget.dispatchEvent(new Event('keyup'));
    }
    messageForUploading(filename) {
        return `![Uploading ${filename}…]()\n`;
    }
    async saveUploadedFile(file) {
        const response = await RailsFetcher.call({
            url: Routes.api_inline_files_path(),
            method: 'POST',
            body: { body: this.uploadedFileData(file) }
        });
        const data = await response.json();
        if (response.ok) {
            return data.url;
        }
        else {
            return data.message;
        }
    }
}
export function disablingSubmitButtonOnUpload(button) {
    return {
        onStart: () => {
            button.disabled = true;
        },
        onEnd: () => {
            button.disabled = false;
        }
    };
}
