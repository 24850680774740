import { Controller } from '@hotwired/stimulus';
import { PreviewClient } from 'api/preview_client';
import { FileAttacher, disablingSubmitButtonOnUpload } from 'api/file_attacher';
import { Confirmable, ConfirmableEvent } from 'mixins/confirmable';
import dragDrop from 'drag-drop';
import Routes from 'common/rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
import { Toast } from 'bootstrap';
import { showPublicDailyReportNotice } from 'common/modal';
import { MarkdownEditable } from 'mixins/markdown_editable';
class default_1 extends MarkdownEditable(Confirmable(Controller)) {
    connect() {
        this.initConfirmableForms([this.formTarget.id]);
        this.client = new PreviewClient({ previewTarget: this.previewTarget, bodyTarget: this.bodyTarget });
        this.fileAttacher = new FileAttacher({
            bodyTarget: this.bodyTarget,
            callback: disablingSubmitButtonOnUpload(this.submitTarget)
        });
        dragDrop(this.bodyTarget, (files) => {
            this.fileAttacher.uploadFiles(files, window);
        });
        this.connectMarkdownEditable(this.bodyTarget, this.previewTarget);
    }
    disconnect() {
        this.disconnectMarkdownEditable();
    }
    updatePreview() {
        this.client.call();
    }
    clickFileUploadButton(e) {
        e.preventDefault();
        this.filesTarget.click();
    }
    changeFiles() {
        const files = this.filesTarget.files;
        if (files === null) {
            throw new Error('No file found');
        }
        this.fileAttacher.uploadFiles(Array.from(files), window);
    }
    pasteClipboardData(e) {
        if (e.clipboardData.types.indexOf('Files') > -1) {
            this.fileAttacher.uploadFiles(Array.from(e.clipboardData.files), window);
            e.preventDefault();
        }
    }
    publish() {
        this.publishTarget.value = 'publish';
    }
    scroll() {
        this.scrollMarkdownPreview();
    }
    open(e) {
        if (this.requireOpenConfirmation()) {
            e.preventDefault();
            this.showOpenConfirmation();
        }
        else {
            this.setOpen();
        }
    }
    save(e) {
        if (e.code === 'KeyS' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            this.submit();
        }
    }
    setOpen() {
        this.publishTarget.value = 'open';
    }
    requireOpenConfirmation() {
        return this.openConfirmedTarget.value !== 'true';
    }
    showOpenConfirmation() {
        if (!this.formTarget.checkValidity()) {
            this.formTarget.reportValidity();
            return;
        }
        const OnClickHandler = () => {
            this.setOpen();
            this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
            this.formTarget.submit();
        };
        const OnHideHandler = () => { };
        // HACK: Confirmableのデータ同期をしないとsystem specで Blocked confirmが出る
        this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
        showPublicDailyReportNotice(OnClickHandler, OnHideHandler);
    }
    submit() {
        this.idValue === 0 ? this.create() : this.update();
    }
    create() {
        RailsFetcher.call({
            url: Routes.daily_report_drafts_path({ format: 'json' }),
            method: 'POST',
            body: this.payload()
        }).then(response => {
            response.json().then(json => {
                this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
                this.idValue = json.id;
                // NOTE: 日報の新規作成は必ず下書きから行われるので遷移先は必ず下書きの編集画面
                this.formTarget.setAttribute('action', Routes.daily_report_draft_path(this.idValue));
                const input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', '_method');
                input.setAttribute('value', 'patch');
                this.formTarget.appendChild(input);
                history.pushState(null, '', Routes.edit_daily_report_draft_path(this.idValue));
                this.showToast();
            });
        });
    }
    update() {
        RailsFetcher.call({
            url: this.updatePath(),
            method: 'PATCH',
            body: this.payload()
        }).then(() => {
            this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
            this.showToast();
        });
    }
    payload() {
        return { daily_report_draft: { title: this.titleTarget.value, body: this.bodyTarget.value, published_on: this.publishedAtTarget.value } };
    }
    updatePath() {
        return Routes.daily_report_draft_path(this.idValue, { format: 'json' });
    }
    showToast() {
        const delay = process.env.NODE_ENV === 'test' ? 10000 : 5000;
        const toast = new Toast(this.alertTarget, { delay });
        toast.show();
    }
}
default_1.targets = ['preview', 'title', 'body', 'files', 'submit', 'form', 'publishedAt', 'publish', 'alert', 'openConfirmed'];
default_1.values = { id: Number, draft: Boolean };
export default default_1;
