import { Controller } from '@hotwired/stimulus';
class NotificationSuggestionController extends Controller {
}
class default_1 extends Controller {
    connect() {
        if (!this.isDisabledSuggestion()) {
            if (!window.OneSignal) {
                window.OneSignal = [];
            }
            window.OneSignal.push(() => {
                window.OneSignal.isPushNotificationsEnabled((isEnabled) => {
                    if (!isEnabled) {
                        // 初回チュートリアルがある場合はキャンセル
                        if (!document.querySelector('.driver-active')) {
                            this.alertTarget.classList.remove('d-none');
                        }
                    }
                });
            });
        }
    }
    enableNotification() {
        if (this.isDisabledSuggestion())
            return;
        this.removeAlert();
        window.OneSignal.push(() => window.OneSignal.registerForPushNotifications());
    }
    disableSuggestion() {
        if (this.isDisabledSuggestion())
            return;
        this.removeAlert();
        window.localStorage.setItem('disableNotificationSuggestion', 'true');
    }
    isDisabledSuggestion() {
        const bodyElement = document.querySelector('body');
        const oneSignalEnabled = bodyElement.dataset.oneSignalEnabled === 'true';
        return oneSignalEnabled && window.localStorage.getItem('disableNotificationSuggestion') === 'true';
    }
    removeAlert() {
        this.alertTarget.classList.add('d-none');
    }
}
default_1.targets = ['alert'];
export default default_1;
