import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
import { showPublicDailyReportNotice } from 'common/modal';
class DailyReportDashboardController extends Controller {
}
class default_1 extends Controller {
    toggleOpenness(event) {
        const target = event.target;
        if (this.askConfirmation(target)) {
            const OnClickHandler = (response) => {
                if (response.ok) {
                    this.selfTarget.dataset.confirmed = 'true';
                }
                this.doUpdateOpenness(target);
            };
            const OnHideHandler = () => {
                target.checked = false;
            };
            showPublicDailyReportNotice(OnClickHandler, OnHideHandler);
        }
        else {
            this.doUpdateOpenness(target);
        }
    }
    askConfirmation(target) {
        if (this.selfTarget.dataset.confirmed === 'true')
            return false;
        // チェックが入った状態（OFFからONになったタイミング）のときだけだけダイアログを出したい。
        // イベント発火後にチェックが入っていない場合は逆の状態なのでスルー
        if (!target.checked)
            return false;
        return true;
    }
    doUpdateOpenness(target) {
        const checked = target.checked;
        const id = target.dataset.testId;
        const payload = {
            daily_report: {
                opened: checked
            }
        };
        if (id === undefined) {
            throw new Error('No ID given');
        }
        RailsFetcher.call({
            url: Routes.daily_report_path(id, { format: 'json' }),
            method: 'PUT',
            body: payload
        }).then(response => {
            if (response.ok) {
                const element = document.querySelector(`.js_publish_button[data-test-id="${id}"]`);
                if (element === null) {
                    throw new Error('No publish button found');
                }
                if (checked) {
                    element.classList.remove('d-none');
                    element.classList.add('d-inline-block');
                }
                else {
                    element.classList.remove('d-inline-block');
                    element.classList.add('d-none');
                }
            }
            else {
                // TODO: ユーザーに分かるようにエラーメッセージ出したい
                console.debug('Unexpected status: ' + response.statusText);
                target.checked = !checked;
            }
        }).catch(() => {
            console.debug('Failed to update openness of the daily report');
            target.checked = !checked;
        });
    }
}
default_1.targets = ['self'];
export default default_1;
