import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
class FeedbacksController extends Controller {
}
class default_1 extends Controller {
    create(e) {
        if (this.locked) {
            return;
        }
        this.locked = true;
        e.preventDefault();
        e.stopPropagation();
        RailsFetcher.call({
            url: Routes.api_feedbacks_path(),
            method: 'POST',
            body: { feedback: { message: this.messageTarget.value } }
        })
            .then(response => {
            if (response.ok) {
                this.displaySuccessMessages();
                this.messageTarget.value = '';
            }
            else {
                response.json().then(json => {
                    this.displayErrorMessages(json.error);
                });
            }
        })
            .finally(() => { this.locked = false; });
    }
    displaySuccessMessages() {
        const successMessage = '<p class="alert alert-success">フィードバックありがとうございます🦁</p>';
        this.displayMessages(successMessage);
    }
    displayErrorMessages(error) {
        const message = `<p class="alert alert-danger">${error}</p>`;
        this.displayMessages(message);
    }
    displayMessages(message) {
        const alert = this.formTarget.querySelector('.alert');
        if (alert) {
            alert.outerHTML = message;
        }
        else {
            this.formTarget.insertAdjacentHTML('afterbegin', message);
        }
    }
}
default_1.targets = ['form', 'message'];
export default default_1;
