/* eslint no-console:0 */
import { Application } from '@hotwired/stimulus';
import '@hotwired/turbo-rails';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import 'driver.js/dist/driver.css';
import '../stylesheets/application.sass';
import 'sentry';
const application = Application.start();
const context = require.context('controllers', true, /.ts$/);
application.load(definitionsFromContext(context));
require('onesignal/index');
require('common');
require('bs/turbo_load');
require('twitter/turbo_load');
require('fontawesome');
require('emoji/turbo_load');
require.context('../images', true);
