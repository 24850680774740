import { markerDetectors } from './markdown_editable/marker';
import { createSpanElementToSimulateSize, simulateTextOffsetHeight } from './markdown_editable/utils';
export function calcEditorStats(editorElement, previewElement) {
    const height = editorElement.scrollHeight;
    const lines = editorElement.value.split('\n');
    const span = createSpanElementToSimulateSize(editorElement);
    if (editorElement.parentNode === null) {
        throw new Error('No parentNode found');
    }
    editorElement.parentNode.appendChild(span);
    const lineToScrollTopMapping = new Map([[0, 0]]);
    const reducer = (prev, line, idx) => {
        const lineno = idx + 1;
        const height = simulateTextOffsetHeight(span, line);
        const scrollTop = prev + height;
        lineToScrollTopMapping.set(lineno, scrollTop);
        return scrollTop;
    };
    lines.reduce(reducer, 0);
    editorElement.parentNode.removeChild(span);
    return {
        height,
        lines,
        editorElement,
        previewElement,
        lineToScrollTopMapping
    };
}
export function computeMarkerPositions({ editorElement, previewElement, editorStats }) {
    const registry = new Map();
    markerDetectors.forEach((detector) => {
        detector.finder(editorStats, editorElement.value, previewElement, registry);
    });
    // NOTE: 0行目を設定して最初の要素までの距離計算を行えるようにしている
    registry.set(0, {
        line: 0,
        node: undefined,
        bodyScrollTop: 0,
        previewScrollTop: 0
    });
    const sortedRegistry = new Map(Array.from(registry.entries()).sort((a, b) => a[0] - b[0]));
    return sortedRegistry;
}
function findPreviousMarker(position, mapping) {
    const entries = Array.from(mapping.entries());
    if (position === 0)
        return entries[0];
    const list = entries.filter((entry) => {
        return (entry[0] < position);
    });
    if (list.length === 0)
        return entries[0];
    return list[list.length - 1];
}
function findNextMarker(position, mapping) {
    const entries = Array.from(mapping.entries());
    const list = entries.filter((entry) => {
        return (entry[0] > position);
    });
    if (list.length === 0)
        return list[list.length - 1];
    return list[0];
}
export function computePreviewScrollTop({ mapping, editorStats }) {
    const editor = editorStats.editorElement;
    const pos = editor.scrollTop;
    const previousMarker = findPreviousMarker(pos, mapping);
    const nextMarker = findNextMarker(pos, mapping);
    const bodyScrollTop = {
        prev: previousMarker ? previousMarker[0] : 0,
        next: nextMarker ? nextMarker[0] : editorStats.height
    };
    const previewScrollTop = {
        prev: previousMarker ? previousMarker[1].previewScrollTop : 0,
        next: nextMarker ? nextMarker[1].previewScrollTop : editorStats.previewElement.scrollHeight
    };
    const bodyDistance = bodyScrollTop.next - bodyScrollTop.prev;
    const previewDistance = previewScrollTop.next - previewScrollTop.prev;
    const ratio = (pos - bodyScrollTop.prev) / bodyDistance;
    // エディタで最下部までスクロールしたときにプレビュー側も最下部までスクロールさせるためにceilを利用。
    // 最上部ではどちらも必ず0になるため。
    return previewScrollTop.prev + Math.ceil(ratio * previewDistance);
}
