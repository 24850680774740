import { Controller } from '@hotwired/stimulus';
import { MutexFetcher } from 'api/mutex_fetcher';
import { enableTooltip } from 'bs';
import { Modal } from 'bootstrap';
class DailyReportMeatsController extends Controller {
}
class default_1 extends Controller {
    connect() {
        this.fetcher = new MutexFetcher();
    }
    create(e) {
        const selection = window.getSelection() || '';
        const url = this.data.get('url');
        if (url === null) {
            throw new Error('No URL found');
        }
        e.stopPropagation();
        this.fetcher.call({
            url,
            method: 'POST',
            body: { quote: selection.toString() },
            callback: (html) => {
                this.selfTarget.outerHTML = html;
                enableTooltip();
            }
        });
    }
    destroy(e) {
        e.stopPropagation();
        const url = this.data.get('url');
        if (url === null) {
            throw new Error('No URL found');
        }
        this.fetcher.call({
            url,
            method: 'DELETE',
            callback: (html) => {
                this.selfTarget.outerHTML = html;
                enableTooltip();
            }
        });
    }
    preventUnselectOnCreateMeat(e) {
        e.preventDefault();
    }
    showMeatgazers() {
        const modalElement = document.getElementById('meatgazersModal');
        if (modalElement === null) {
            throw new Error('No modal found');
        }
        const body = modalElement.getElementsByClassName('modal-body');
        const url = this.data.get('meatgazers-url');
        if (url === null) {
            throw new Error('No url found');
        }
        this.fetcher.call({
            url,
            method: 'GET',
            callback: (html) => {
                body[0].innerHTML = html;
                const m = new Modal(modalElement);
                m.show();
            }
        });
    }
}
default_1.targets = ['self'];
export default default_1;
