import { Controller } from '@hotwired/stimulus';
class FriendListController extends Controller {
}
class default_1 extends Controller {
    connect() {
        if (this.thumbsTarget.scrollHeight <= 41 * 3 && this.hasButtonTarget) {
            this.buttonTarget.remove();
        }
    }
    toggle() {
        if (this.thumbsTarget.classList.contains('friend-thumbnail--show')) {
            this.thumbsTarget.classList.remove('friend-thumbnail--show');
            this.buttonTarget.textContent = 'すべて表示する';
        }
        else {
            this.thumbsTarget.classList.add('friend-thumbnail--show');
            this.buttonTarget.textContent = 'もとに戻す';
        }
    }
}
default_1.targets = ['thumbs', 'button'];
export default default_1;
