export class RailsFetcher {
    static call(data) {
        const csrfToken = () => {
            const meta = document.querySelector('meta[name=csrf-token]');
            return meta && meta.content;
        };
        const token = csrfToken() || '';
        const options = {
            method: data.method,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': token
            },
            credentials: 'include'
        };
        if (data.method.toLowerCase() !== 'get' && data.body) {
            options.body = JSON.stringify(data.body);
        }
        return fetch(data.url, options);
    }
}
