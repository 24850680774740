/* eslint-enable */
import { calcEditorStats, computeMarkerPositions, computePreviewScrollTop } from 'common/markdown_preview_scroller';
import { ApplyMarkdownInputAssist } from 'esalike';
export function MarkdownEditable(Base) {
    return class extends Base {
        connectMarkdownEditable(editor, preview) {
            ApplyMarkdownInputAssist(editor);
            const updateMarkdownEditable = ({ editor, preview }) => {
                this.markdownEditableEditorStats = calcEditorStats(editor, preview);
                this.markdownEditablePositions = computeMarkerPositions({
                    editorElement: editor,
                    previewElement: preview,
                    editorStats: this.markdownEditableEditorStats
                });
            };
            updateMarkdownEditable({ editor, preview });
            // NOTE: Turboのページ遷移による初回ロード時はレンダリングが行われる前にpreviewTargetにconnect
            //       されてしまうため、レンダリングをResizeObserverで検出している。
            const observer = new ResizeObserver(() => {
                updateMarkdownEditable({ editor, preview });
            });
            observer.observe(preview);
            this.markdownEditablePreviewResizeObserver = observer;
        }
        disconnectMarkdownEditable() {
            this.markdownEditablePreviewResizeObserver.disconnect();
        }
        scrollMarkdownPreview() {
            const next = computePreviewScrollTop({
                mapping: this.markdownEditablePositions,
                editorStats: this.markdownEditableEditorStats
            });
            const parent = this.markdownEditableEditorStats.previewElement.parentElement;
            if (!parent)
                return;
            parent.scrollTop = next;
        }
    };
}
