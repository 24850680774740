import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';
import { MutexFetcher } from 'api/mutex_fetcher';
/* eslint-enable */
import Routes from 'common/rails_routes';
import { Turbo } from '@hotwired/turbo-rails';
import decorateMessageHtml from 'common/decorate_html_message';
class MessagesController extends Controller {
}
class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.noMoreLoading = false;
        this.loading = false;
    }
    connect() {
        this.fetcher = new MutexFetcher();
        this.subscribe();
        const loadingMessage = this.scrollableTarget.querySelector('.message__scrollable-loading');
        if (loadingMessage === null) {
            throw new Error("No element that has class '.message__scrollable-loading' found");
        }
        loadingMessage.classList.add('d-none');
        this.roomTarget.classList.remove('d-none');
        this.scrollableTarget.scrollTop = this.scrollableTarget.scrollHeight;
        this.bodyTarget.value = this.getInitialBody();
    }
    getInitialBody() {
        // data属性の指定方法はStimulusの流儀に合わせているが、このコントローラが割り当てられるDOMの外側にある要素を
        // 参照しているため、valueではなくdocument.querySelectorで取得している。
        const elem = document.querySelector('[data-messages-initial-body-value]');
        if (elem === null) {
            return '';
        }
        const value = elem.dataset.messagesInitialBodyValue;
        if (value === undefined) {
            throw new Error('No messagersInitialBodyValue has been defined');
        }
        return value;
    }
    disconnect() {
        if (this.subscription) {
            consumer.subscriptions.remove(this.subscription);
            this.subscription = null;
        }
        if (this.messageMutationObserver) {
            this.messageMutationObserver.disconnect();
        }
    }
    loadMessages(e) {
        if (this.scrollableTarget.scrollTop !== 0)
            return;
        if (this.noMoreLoading)
            return;
        if (this.loading)
            return;
        const message = this.roomTarget.querySelector('li:first-of-type');
        const firstMessageId = this.scrollableTarget.dataset.firstMessageId;
        if (message.dataset.messageId === firstMessageId) {
            this.noMoreLoading = true;
            return;
        }
        e.preventDefault();
        this.loading = true;
        const roomCode = this.data.get('roomCode');
        if (roomCode === null) {
            throw new Error('No roomCode found');
        }
        const path = Routes.fragment_api_room_path(roomCode);
        const offsetFromBottom = this.roomTarget.scrollHeight - message.clientTop;
        const spinner = this.spinnerTarget;
        spinner.classList.remove('d-none');
        this.fetcher.call({
            url: `${path}?message_id=${message.dataset.messageId}`,
            method: 'GET',
            callback: (html) => {
                const updateMessages = () => {
                    spinner.classList.add('d-none');
                    this.roomTarget.insertAdjacentHTML('afterbegin', html);
                    this.loading = false;
                    if (!html.trim()) {
                        this.scrollableTarget.scrollTop = 0;
                        this.noMoreLoading = true;
                    }
                    else {
                        this.scrollableTarget.scrollTop = this.scrollableTarget.scrollHeight - offsetFromBottom;
                    }
                };
                window.setTimeout(updateMessages, 500);
            }
        });
    }
    toggleMute(e) {
        const muted = this.data.get('muted');
        const roomCode = this.data.get('roomCode');
        if (roomCode === null) {
            throw new Error('No roomCode found');
        }
        const path = muted === 'true'
            ? Routes.unmute_api_room_path(roomCode)
            : Routes.mute_api_room_path(roomCode);
        e.preventDefault();
        e.stopPropagation();
        this.fetcher.call({
            url: path,
            method: 'PATCH',
            callback: () => {
                const newState = (muted !== 'true').toString();
                this.data.set('muted', newState);
                this.muteTarget.classList.toggle('d-none');
                this.unmuteTarget.classList.toggle('d-none');
            }
        });
    }
    send(e) {
        e.preventDefault();
        e.stopPropagation();
        const body = this.bodyTarget;
        const submit = this.submitButtonTarget;
        submit.innerHTML = '送信する <i class="fas fa-spinner fa-spin"></i>';
        submit.disabled = true;
        this.subscription.speak(body.value);
        submit.disabled = false;
        submit.innerHTML = '送信する';
    }
    remove(e) {
        if (confirm('本当に削除しますか？')) {
            const container = e.target.closest('[data-message-id]');
            const messageId = container.dataset.messageId;
            this.subscription.remove(messageId);
        }
    }
    subscribe() {
        const roomCode = this.data.get('roomCode');
        const receiverId = this.data.get('receiverId');
        const senderId = this.data.get('senderId');
        const submit = this.submitButtonTarget;
        const body = this.bodyTarget;
        const scrollableTarget = this.scrollableTarget;
        if (senderId === null) {
            throw new Error('No senderId found');
        }
        const messageFrameCallback = (mutations) => {
            mutations.forEach((mutation) => {
                decorateMessageHtml(mutation.target, parseInt(senderId, 10));
            });
        };
        this.messageMutationObserver = new MutationObserver((mutations) => messageFrameCallback(mutations));
        this.messageMutationObserver.observe(this.roomTarget, { childList: true, subtree: true });
        this.subscription = consumer.subscriptions.create({
            channel: 'ChatChannel',
            room_code: roomCode
        }, {
            connected() {
                const messageContainer = document.getElementById('websocket-error-message');
                if (messageContainer)
                    messageContainer.remove();
                submit.disabled = false;
            },
            disconnected() {
                const messageContainer = document.getElementById('websocket-error-message');
                if (messageContainer)
                    return;
                const message = `
            エラーが発生しました。ネットワーク状況をご確認ください。問題なくインターネットにつながっている場合はログインしなおして再度メッセージ機能をお試しください。もしログインしなおしてもこの文章が表示される場合はお問い合わせください。
          `;
                const elem = document.createElement('div');
                elem.id = 'websocket-error-message';
                elem.className = 'alert alert-danger m-4';
                elem.textContent = message;
                const header = document.querySelector('header');
                header.insertAdjacentElement('afterend', elem);
                submit.disabled = true;
            },
            rejected() {
            },
            received(data) {
                if (data.type === 'speak') {
                    this.renderStreamMessage(data);
                    if (data.user_id === senderId) {
                        body.value = '';
                    }
                    // FIXME: メッセージの追加が非同期で実行されるため、setTimeoutしないと
                    // 最新の一件手前までしかスクロールしない。もっといい感じのやり方求む
                    setTimeout(() => {
                        scrollableTarget.scrollTop = scrollableTarget.scrollHeight;
                    }, 100);
                }
                else { // remove
                    this.renderStreamMessage(data);
                }
            },
            speak(body) {
                this.perform('speak', {
                    room_code: roomCode,
                    receiver_id: receiverId,
                    body
                });
            },
            remove(messageId) {
                this.perform('remove', {
                    room_code: roomCode,
                    message_id: messageId
                });
            },
            renderStreamMessage(data) {
                Turbo.renderStreamMessage(data.body);
            }
        });
    }
}
default_1.targets = ['body', 'room', 'submitButton', 'scrollable', 'mute', 'unmute', 'spinner'];
export default default_1;
