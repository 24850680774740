import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { MutexFetcher } from 'api/mutex_fetcher';
/* eslint-enable */
// 割り当てられた文字列が使われていないと判定されてno-unused-vars警告が出てしまうのを
// 抑制。実際には利用されている。
/* eslint-disable no-unused-vars */
var NotificationType;
(function (NotificationType) {
    NotificationType["DailyBoard"] = "receiveDailyBoardNotification";
    NotificationType["DailyCondition"] = "receiveDailyConditionNotification";
    NotificationType["DailyDailyReport"] = "receiveDailyDailyReportNotification";
    NotificationType["DailyMeat"] = "receiveDailyMeatNotification";
    NotificationType["Message"] = "receiveMessageNotification";
})(NotificationType || (NotificationType = {}));
/* eslint-enable */
class NotificationsController extends Controller {
}
class default_1 extends Controller {
    connect() {
        this.fetcher = new MutexFetcher();
    }
    updateReceiveDailyBoardNotification(e) {
        this.update(e, NotificationType.DailyBoard);
    }
    updateReceiveDailyConditionNotification(e) {
        this.update(e, NotificationType.DailyCondition);
    }
    updateReceiveDailyDailyReportNotification(e) {
        this.update(e, NotificationType.DailyDailyReport);
    }
    updateReceiveDailyMeatNotification(e) {
        this.update(e, NotificationType.DailyMeat);
    }
    updateReceiveMessageNotification(e) {
        this.update(e, NotificationType.Message);
    }
    update(e, notificationType) {
        e.preventDefault();
        e.stopPropagation();
        const prop = this.convertCamelCaseToSnakeCase(notificationType);
        const targetName = notificationType + 'Target';
        const target = Reflect.get(this, targetName);
        const eventTarget = e.currentTarget;
        const booleanText = eventTarget.querySelector('input').value;
        const params = {
            notification: {
                [prop]: booleanText === 'true'
            }
        };
        // 念のため実行時の値チェックを行っておく
        if (!['true', 'false'].includes(booleanText))
            return;
        if (target.dataset.value === booleanText)
            return;
        this.fetcher.call({
            url: Routes.settings_notification_path(),
            method: 'PATCH',
            body: params,
            callback: () => {
                target.dataset.value = booleanText;
                const radios = target.querySelectorAll('input');
                Array.from(radios).forEach(radio => {
                    radio.checked = (radio.value === booleanText);
                });
            }
        });
    }
    convertCamelCaseToSnakeCase(text) {
        return text.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return '_' + y.toLowerCase(); }).replace(/^_/, '');
    }
}
default_1.targets = Object.values(NotificationType);
export default default_1;
