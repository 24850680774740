import { Controller } from '@hotwired/stimulus';
import { Confirmable } from 'mixins/confirmable';
class CareersController extends Controller {
}
class default_1 extends Confirmable(Controller) {
    connect() {
        this.initConfirmableForms([this.formTarget.id]);
    }
}
default_1.targets = ['form'];
export default default_1;
