import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';
class NotificationController extends Controller {
}
class NotificationUpdater {
    static updateTitle(count) {
        if (count === 0)
            return;
        const counter = `(${count})`;
        const titleElement = document.querySelector('title');
        if (titleElement === null) {
            throw new Error('No title element');
        }
        titleElement.textContent = counter + ' ' + NotificationUpdater.title;
    }
}
NotificationUpdater.title = '';
class default_1 extends Controller {
    connect() {
        NotificationUpdater.title = this.titleValue;
        NotificationUpdater.updateTitle(this.countValue);
        this.subscribe();
    }
    disconnect() {
        if (this.subscription) {
            consumer.subscriptions.remove(this.subscription);
            this.subscription = null;
        }
    }
    subscribe() {
        this.subscription = consumer.subscriptions.create({
            channel: 'NotificationChannel'
        }, {
            received(data) {
                NotificationUpdater.updateTitle(data.count);
                this.updateNotificationNav && this.updateNotificationNav(data.html);
            },
            updateNotificationNav(html) {
                const element = document.querySelector('#notifications_label');
                if (element !== null) {
                    element.innerHTML = html;
                }
            }
        });
    }
}
default_1.values = { count: Number, title: String };
export default default_1;
