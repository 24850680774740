import { Controller } from '@hotwired/stimulus';
import { MutexFetcher } from 'api/mutex_fetcher';
import Routes from 'common/rails_routes';
import { Modal } from 'bootstrap';
import decorateMessageHtml from 'common/decorate_html_message';
class MessageDashboardController extends Controller {
}
class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    connect() {
        this.fetcher = new MutexFetcher();
        const roomCode = this.initialRoomCodeValue;
        if (this.messageCountValue === 0) {
            this.searchAreaTarget.classList.add('d-none');
        }
        // roomCodeはプロフィールページから直接相手を指定したとき等に利用されため、
        // 過去のメッセージが一切ない場合でもblank画面は表示せず、新規会話作成ダイアログを
        // バイパスして会話画面を出す
        if (!roomCode && this.messageCountValue === 0) {
            this.blankTarget.classList.remove('d-none');
            return;
        }
        if (roomCode) {
            this.loadConversation(roomCode);
        }
    }
    startConversation() {
        new Modal(this.newConversationTarget.children[0]).show();
    }
    clickConversation(e) {
        if (this.loading)
            return;
        e.preventDefault();
        const currentTarget = e.currentTarget;
        const roomCode = currentTarget.dataset.roomCode;
        if (roomCode === undefined) {
            throw new Error('No roomCode found');
        }
        this.loadConversation(roomCode);
    }
    loadConversation(roomCode) {
        this.loading = true;
        const spinner = this.spinnerTarget;
        spinner.classList.toggle('d-none');
        this.conversationTarget.innerHTML = '<div></div>';
        this.blankTarget.classList.add('d-none');
        const url = Routes.messages_conversation_stream_path(roomCode);
        this.fetcher.call({
            url,
            method: 'GET',
            callback: (html) => {
                this.conversationTarget.insertAdjacentHTML('afterbegin', html);
                decorateMessageHtml(this.conversationTarget, this.currentUserIdValue);
                spinner.classList.toggle('d-none');
                this.loading = false;
            }
        });
    }
}
default_1.targets = ['sidebar', 'conversation', 'spinner', 'newConversation', 'blank', 'searchArea'];
default_1.values = { messageCount: Number, initialRoomCode: String, currentUserId: Number };
export default default_1;
