"use strict";
document.addEventListener('keydown', (e) => {
    if (!(e.key === 'Enter' && (e.ctrlKey || e.metaKey))) {
        return;
    }
    const form = e.target.form;
    if (!form)
        return;
    const event = new Event('submit', { bubbles: true, cancelable: true });
    if (form.dispatchEvent(event)) {
        form.submit();
    }
});
