import { Controller } from '@hotwired/stimulus';
class InvitationController extends Controller {
}
class default_1 extends Controller {
    copy(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const url = target.dataset.url;
        if (url === undefined) {
            throw new Error('No URL found');
        }
        navigator.clipboard.writeText(url).then(() => {
            this.copyMessageTarget.classList.add('opacity-100');
            setTimeout(() => {
                this.copyMessageTarget.classList.remove('opacity-100');
            }, 2500);
        });
    }
}
default_1.targets = ['copyMessage'];
export default default_1;
