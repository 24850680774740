import { Controller } from '@hotwired/stimulus';
import { PreviewClient } from 'api/preview_client';
import { FileAttacher, disablingSubmitButtonOnUpload } from 'api/file_attacher';
import { Confirmable, ConfirmableEvent } from 'mixins/confirmable';
import dragDrop from 'drag-drop';
import Routes from 'common/rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
import { Toast } from 'bootstrap';
import { showPublicDailyReportNotice } from 'common/modal';
import { DailyReportControllerCommon } from 'types';
import { MarkdownEditable } from 'mixins/markdown_editable';
class DailyReportController extends DailyReportControllerCommon {
}
class default_1 extends MarkdownEditable(Confirmable(Controller)) {
    connect() {
        this.initConfirmableForms([this.formTarget.id]);
        this.client = new PreviewClient({ previewTarget: this.previewTarget, bodyTarget: this.bodyTarget });
        this.fileAttacher = new FileAttacher({
            bodyTarget: this.bodyTarget,
            callback: disablingSubmitButtonOnUpload(this.submitTarget)
        });
        dragDrop(this.bodyTarget, (files) => {
            this.fileAttacher.uploadFiles(files, window);
        });
        this.connectMarkdownEditable(this.bodyTarget, this.previewTarget);
    }
    disconnect() {
        this.disconnectMarkdownEditable();
    }
    scroll() {
        this.scrollMarkdownPreview();
    }
    updatePreview() {
        this.client.call();
    }
    clickFileUploadButton(e) {
        e.preventDefault();
        this.filesTarget.click();
    }
    changeFiles() {
        const files = this.filesTarget.files;
        if (files === null) {
            throw new Error('No files found');
        }
        this.fileAttacher.uploadFiles(Array.from(files), window);
    }
    pasteClipboardData(e) {
        if (e.clipboardData.types.indexOf('Files') > -1) {
            this.fileAttacher.uploadFiles(Array.from(e.clipboardData.files), window);
            e.preventDefault();
        }
    }
    open(e) {
        if (!this.confirmedValue) {
            e.preventDefault();
            this.showOpenConfirmation();
        }
        else {
            this.setOpen();
        }
    }
    save(e) {
        if (e.code === 'KeyS' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            this.submit();
        }
    }
    setOpen() {
        this.publishTarget.value = 'true';
    }
    requireOpenConfirmation() {
        return this.openConfirmedTarget.value !== 'true';
    }
    showOpenConfirmation() {
        if (!this.formTarget.checkValidity()) {
            this.formTarget.reportValidity();
            return;
        }
        const OnClickHandler = () => {
            this.setOpen();
            this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
            this.formTarget.submit();
        };
        const OnHideHandler = () => { };
        // HACK: Confirmableのデータ同期をしないとsystem specで Blocked confirmが出る
        this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
        showPublicDailyReportNotice(OnClickHandler, OnHideHandler);
    }
    submit() {
        this.update();
    }
    update() {
        RailsFetcher.call({
            url: this.updatePath(),
            method: 'PATCH',
            body: this.payload()
        }).then(() => {
            this.formTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
            this.showToast();
        });
    }
    payload() {
        return { daily_report: { body: this.bodyTarget.value, title: this.titleTarget.value } };
    }
    updatePath() {
        return Routes.daily_report_path(this.idValue, { format: 'json' });
    }
    showToast() {
        const delay = process.env.NODE_ENV === 'test' ? 10000 : 5000;
        const toast = new Toast(this.alertTarget, { delay });
        toast.show();
    }
}
default_1.targets = ['preview', 'title', 'body', 'files', 'submit', 'form', 'publishedAt', 'publish', 'alert', 'openConfirmed'];
default_1.values = { id: Number, confirmed: Boolean };
export default default_1;
