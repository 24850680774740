import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { Turbo } from '@hotwired/turbo-rails';
import { MutexFetcher } from 'api/mutex_fetcher';
class NewConversationController extends Controller {
}
class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.throttlingSearch = false;
    }
    connect() {
        this.fetcher = new MutexFetcher();
        if (this.throttlingSearch)
            return;
        const self = this;
        setTimeout(function () {
            self.throttlingSearch = false;
            self.doSearch();
        }, 1000);
    }
    search(e) {
        e.preventDefault();
        if (this.throttlingSearch)
            return;
        this.throttlingSearch = true;
        const self = this;
        setTimeout(function () {
            self.throttlingSearch = false;
            self.doSearch();
        }, 1000);
    }
    doSearch() {
        // TODO: spinnerいるかどうか考える
        const url = Routes.api_recipients_path({ q: this.searchTextTarget.value });
        this.friendsListTarget.innerHTML = '';
        this.fetcher.call({
            url,
            method: 'GET',
            callback: (html) => {
                this.friendsListTarget.insertAdjacentHTML('afterbegin', html);
            }
        });
    }
    choose(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const elements = this.friendsListTarget.querySelectorAll('li.list-group-item');
        Array.prototype.forEach.call(elements, (elem) => {
            elem.classList.remove('active');
        });
        target.classList.toggle('active');
    }
    start(e) {
        e.preventDefault();
        const chosenElement = this.friendsListTarget.querySelector('li.list-group-item.active');
        if (chosenElement === null)
            return;
        const friendId = chosenElement.dataset.userId;
        if (friendId === undefined) {
            throw new Error('No userId found');
        }
        const roomCode = [this.currentUserIdValue, parseInt(friendId)].sort((a, b) => a - b).join(':');
        Turbo.visit(Routes.messages_conversation_path(roomCode));
    }
}
default_1.values = {
    currentUserId: Number
};
default_1.targets = ['searchText', 'errorMessage', 'friendsList'];
export default default_1;
