import { Controller } from '@hotwired/stimulus';
import { PreviewClient } from 'api/preview_client';
import { FileAttacher, disablingSubmitButtonOnUpload } from 'api/file_attacher';
import { Confirmable, ConfirmableEvent } from 'mixins/confirmable';
import dragDrop from 'drag-drop';
import { MarkdownEditable } from 'mixins/markdown_editable';
import { applyTwitterDecoration } from 'twitter';
class BoardsController extends Controller {
}
const supportedVisibilityLevel = ['friends', 'savanna'];
class default_1 extends MarkdownEditable(Confirmable(Controller)) {
    connect() {
        this.initConfirmableForms([this.formTarget.id]);
        this.client = new PreviewClient({ previewTarget: this.previewTarget, bodyTarget: this.bodyTarget });
        this.fileAttacher = new FileAttacher({ bodyTarget: this.bodyTarget, callback: disablingSubmitButtonOnUpload(this.submitTarget) });
        dragDrop(this.bodyTarget, (files) => {
            this.fileAttacher.uploadFiles(files, window);
        });
        applyTwitterDecoration(this.previewTarget);
        this.connectMarkdownEditable(this.bodyTarget, this.previewTarget);
    }
    disconnect() {
        this.disconnectMarkdownEditable();
    }
    scroll() {
        this.scrollMarkdownPreview();
    }
    updatePreview() {
        this.client.call();
        // HACK: Confirmableのデータ同期をしないとsystem specで Blocked confirmが出る
        this.previewTarget.dispatchEvent(new Event(ConfirmableEvent.reset));
    }
    updateVisibility(event) {
        const target = event.currentTarget;
        // NOTE: supportedVisibilityLevel.includeでのフィルタリングでは型推論できなかったのでfindを使っている
        const level = supportedVisibilityLevel.find((str) => str === target.dataset.level);
        if (level) {
            this.cvOutlet.setLevel(level);
        }
    }
    clickFileUploadButton(event) {
        event.preventDefault();
        this.filesTarget.click();
    }
    changeFiles() {
        const files = this.filesTarget.files;
        if (files === null) {
            throw new Error('No files found');
        }
        this.fileAttacher.uploadFiles(Array.from(files), window);
    }
    pasteClipboardData(e) {
        if (e.clipboardData.types.indexOf('Files') > -1) {
            this.fileAttacher.uploadFiles(Array.from(e.clipboardData.files), window);
            e.preventDefault();
        }
    }
    metaToggle(event) {
        event.preventDefault();
        const klass = 'savanna-editor__meta--close';
        const meta = this.metaTarget;
        const toggle = this.toggleTarget;
        if (meta.classList.contains(klass)) {
            meta.classList.remove(klass);
            toggle.textContent = '閉じる';
        }
        else {
            meta.classList.add(klass);
            toggle.textContent = '開く';
        }
    }
}
default_1.targets = ['preview', 'body', 'files', 'submit', 'form', 'meta', 'toggle'];
default_1.outlets = ['cv'];
export default default_1;
