import RailsExportedConfig from 'common/rails_exported_config';
if (!window.OneSignal) {
    window.OneSignal = [];
}
const body = document.querySelector('body');
const oneSignalEnabled = body.dataset.oneSignalEnabled;
if (oneSignalEnabled === 'true') {
    window.OneSignal.push(['init', {
            appId: RailsExportedConfig.oneSignalAppId,
            allowLocalhostAsSecureOrigin: true,
            autoRegister: false,
            notifyButton: {
                enable: false
            }
        }]);
}
document.addEventListener('turbo:load', () => {
    const body = document.querySelector('body');
    const userId = body.dataset.userId;
    const oneSignalEnabled = body.dataset.oneSignalEnabled;
    if (oneSignalEnabled && userId)
        window.OneSignal.push(() => window.OneSignal.sendTag('user_id', userId));
});
