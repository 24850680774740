import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { MutexFetcher } from 'api/mutex_fetcher';
export default class extends Controller {
    connect() {
        this.fetcher = new MutexFetcher();
    }
    acceptOffer(event) {
        const target = event.target;
        this.fetcher.call({
            url: Routes.accepted_offers_path(),
            method: 'POST',
            body: { offer_id: target.dataset.offerId },
            callback: () => {
                const parentElement = target.parentElement;
                if (parentElement === null) {
                    throw new Error('No parent element');
                }
                Array.from(parentElement.getElementsByClassName('btn-offer-suspend')).forEach(elem => elem.remove());
                target.outerHTML = '<span class="btn btn-default btn-friend">友達</span>';
            }
        });
    }
    suspendOffer(event) {
        const target = event.target;
        this.fetcher.call({
            url: Routes.pending_offers_path(),
            method: 'POST',
            body: { offer_id: target.dataset.offerId },
            callback: () => {
                const parentElement = target.parentElement;
                if (parentElement === null) {
                    throw new Error('No parent element');
                }
                Array.from(parentElement.getElementsByClassName('btn-offer-accept')).forEach(elem => elem.remove());
                target.outerHTML = '<span class="btn btn-default btn-friend">保留済み</span>';
            }
        });
    }
    sendOffer(event) {
        const target = event.target;
        this.fetcher.call({
            url: Routes.offers_path(),
            method: 'POST',
            body: { receiver_id: target.dataset.receiverId },
            callback: () => {
                const parentElement = target.parentElement;
                if (parentElement === null) {
                    throw new Error('No parent element');
                }
                Array.from(parentElement.getElementsByClassName('btn-offer-suspend')).forEach(elem => elem.remove());
                target.outerHTML = '<span class="btn btn-primary btn-offer-sent disabled">友達申請済み</span>';
            }
        });
    }
}
