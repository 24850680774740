import { Controller } from '@hotwired/stimulus';
import { driver } from 'driver.js';
import Routes from 'common/rails_routes';
import { MutexFetcher } from 'api/mutex_fetcher';
import * as bootstrap from 'bootstrap';
export default class extends Controller {
    connect() {
        if (this.data.get('status') === 'false') {
            this.startTour();
        }
    }
    startTour() {
        const finishTour = () => {
            const dropdown = document.querySelector('.navigation .dropdown-menu');
            if (dropdown === null) {
                throw new Error('No dropdown menu found');
            }
            dropdown.classList.remove('show');
            window.scroll(0, 0);
            const fetcher = new MutexFetcher();
            fetcher.call({
                url: Routes.api_tour_status_path(),
                method: 'PATCH',
                body: { user: { toured: true } },
                callback: () => {
                    this.data.set('status', 'true');
                }
            });
        };
        const offcanvasElement = document.getElementById('top-navbar-offcanvas');
        const offcanvas = new bootstrap.Offcanvas(offcanvasElement);
        const dropdownElement = document.querySelector('a[data-testid="profile_nav"]');
        const dropdown = new bootstrap.Dropdown(dropdownElement);
        // 「日報を書く」を正しくハイライトするために、モバイルサイズの画面でオフキャンバスがあればそれを表示する
        const showOffcanvasAndNext = () => {
            offcanvasElement.addEventListener('shown.bs.offcanvas', () => {
                driverObj.moveNext();
            }, { once: true });
            offcanvas.show();
        };
        // 初期位置に戻るために、オフキャンバスが表示状態であれば非表示にする
        const hideOffcanvasAndPrev = () => {
            offcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
                driverObj.movePrevious();
            }, { once: true });
            offcanvas.hide();
        };
        const showDropdownAndNext = () => {
            dropdownElement.addEventListener('shown.bs.dropdown', () => {
                driverObj.moveNext();
            }, { once: true });
            dropdown.show();
        };
        const showDropdownAndOffcanvasAndPrev = () => {
            offcanvasElement.addEventListener('shown.bs.offcanvas', () => {
                driverObj.movePrevious();
            }, { once: true });
            dropdown.show();
            offcanvas.show();
        };
        const hideDropdownAndOffcanvasAndNext = () => {
            offcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
                driverObj.moveNext();
            }, { once: true });
            dropdown.hide();
            offcanvas.hide();
        };
        const hideDropdownAndPrev = () => {
            dropdownElement.addEventListener('hidden.bs.dropdown', () => {
                driverObj.movePrevious();
            }, { once: true });
            dropdown.hide();
        };
        const introduceSavanna = {
            element: '.js_intro__title',
            popover: {
                title: '🦁 savannaにようこそ',
                description: 'savannaは友達同士でおしごと情報を共有できるサービスです。仕事や仕事探しをもっと楽しく、もっと便利にしたいという思いから生まれました。',
                side: 'bottom',
                onNextClick: showOffcanvasAndNext
            }
        };
        const explainDailyReport = {
            element: '.js_intro__daily_report',
            popover: {
                title: '🦁 日報を書こう！',
                description: '日々のお仕事のなかで感じたことをともだちに共有してみましょう。ただしNDA違反には気をつけて！',
                side: 'bottom',
                onNextClick: showDropdownAndNext,
                onPrevClick: hideOffcanvasAndPrev
            }
        };
        const explainCondition = {
            element: '.js_intro__condition',
            popover: {
                title: '🦁 お仕事状況が変わったら共有しよう！',
                description: '現在のお仕事状況が変わったときに情報を更新しておくと、ともだちに個別に状況を伝える手間が省けます。新しいお仕事先を探すときなどに特に便利です。',
                side: 'bottom',
                onNextClick: hideDropdownAndOffcanvasAndNext,
                onPrevClick: hideDropdownAndPrev
            }
        };
        const explainFriends = {
            element: '.js_intro__friends',
            popover: {
                title: '🦁 ともだちを増やそう！',
                description: 'すでにsavannaに登録済みの知人を探してともだちリクエストを送信してみましょう。ともだちになることで、初めてその人の日報やお仕事状況を見ることができます。',
                side: 'bottom',
                onPrevClick: showDropdownAndOffcanvasAndPrev
            }
        };
        const driverObj = driver({
            animate: false,
            allowClose: false,
            nextBtnText: '次に進む',
            prevBtnText: '前に戻る',
            doneBtnText: 'savannaを始める！',
            overlayOpacity: 0.6,
            onDestroyed: finishTour,
            steps: [introduceSavanna, explainDailyReport, explainCondition, explainFriends]
        });
        driverObj.drive();
    }
}
