import { Controller } from '@hotwired/stimulus';
import { applyTwitterDecoration } from 'twitter';
class EmbeddedTweetController extends Controller {
}
// ツイートの埋め込みURLをwidgets.jsを使ってデコレーションするためのコントローラ。
// ページ全体での widgets.load はパフォーマンスが悪化するのでコントローラを利用している。
//
// 他のコントローラで対応できる場合はこのコントローラを利用せず、applyTwitterDecoration
// connectメソッドで呼び出すこと。
class default_1 extends Controller {
    connect() {
        const target = this.bodyTarget;
        applyTwitterDecoration(target);
        this.turboListener = (e) => {
            const eventElement = e.target;
            if (target.isEqualNode(eventElement) || target.contains(eventElement)) {
                applyTwitterDecoration(eventElement);
            }
        };
        document.addEventListener('turbo:frame-load', this.turboListener);
    }
    disconnect() {
        document.removeEventListener('turbo:frame-load', this.turboListener);
    }
}
default_1.targets = ['body'];
export default default_1;
