import { Alert } from 'bootstrap';
import { Controller } from '@hotwired/stimulus';
class AutoDismissFlashController extends Controller {
}
class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.modalTimeout = 5000;
    }
    connect() {
        if (process.env.NODE_ENV !== 'test') {
            setTimeout(() => {
                new Alert(this.alertTarget).close();
            }, this.modalTimeout);
        }
    }
}
default_1.targets = ['alert'];
export default default_1;
