import Routes from 'common/rails_routes';
import { applyTwitterDecoration } from 'twitter';
import { RailsFetcher } from 'api/rails_fetcher';
const State = {
    done: 'done',
    waiting: 'waiting'
};
export class PreviewClient {
    constructor({ previewTarget, bodyTarget }) {
        this.locked = false;
        this.previewTarget = previewTarget;
        this.bodyTarget = bodyTarget;
        this.previewTarget.dataset.state = State.done;
    }
    call(delay = 1000) {
        if (this.locked) {
            return false;
        }
        this.locked = true;
        setTimeout(() => {
            this.locked = false;
            this.previewTarget.dataset.state = State.waiting;
            RailsFetcher.call({
                url: Routes.api_preview_path(),
                method: 'POST',
                body: { body: this.bodyTarget.value }
            })
                .then(response => response.text())
                .then(html => {
                this.previewTarget.innerHTML = html;
                this.previewTarget.dataset.state = State.done;
            }).then(() => {
                applyTwitterDecoration(this.previewTarget);
            });
        }, delay);
    }
}
