// textarea内の文字列に対して高さを取るDOM APIがないため、textareaから必要なスタイルをコピーした
// spanタグを生成し、文字列の高さを取得している
export function createSpanElementToSimulateSize(editorElement) {
    const elem = document.createElement('span');
    elem.style.fontSize = window.getComputedStyle(editorElement).getPropertyValue('font-size');
    elem.style.fontFamily = window.getComputedStyle(editorElement).getPropertyValue('font-family');
    elem.style.lineHeight = window.getComputedStyle(editorElement).getPropertyValue('line-height');
    return elem;
}
export function simulateTextOffsetHeight(element, text) {
    // NOTE: 空白文字列を入れただけだとspanタグのoffsetHeightが生成されないので適当な文字を入れている
    element.textContent = text.length > 0 ? text : 'a';
    return element.offsetHeight;
}
