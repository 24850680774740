"use strict";
// 以下のURLからコピーしてTS化
// https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites
function assignTwitterWidgetLoader() {
    window.twttr = (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        const t = window.twttr || {};
        if (d.getElementById(id))
            return t;
        const js = d.createElement(s);
        js.id = id;
        js.src = 'https://platform.twitter.com/widgets.js';
        if (!fjs.parentNode)
            return;
        fjs.parentNode.insertBefore(js, fjs);
        t._e = [];
        t.ready = function (f) {
            t._e.push(f);
        };
        return t;
    }(document, 'script', 'twitter-wjs'));
}
function mockTwitterWidgetLoader() {
    window.twttr = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ready: (_obj) => {
            // DO NOTHING
        }
    };
}
// widgets.js読み込み時にエラーが発生することがあるため、テスト時には読み込まない。
const handler = process.env.NODE_ENV === 'test' ? mockTwitterWidgetLoader : assignTwitterWidgetLoader;
handler(); // ページリロード時に window.twttr が undefined になるのを防止
document.addEventListener('turbo:load', handler);
