import { Controller } from '@hotwired/stimulus';
import { MutexFetcher } from 'api/mutex_fetcher';
import { enableTooltip } from 'bs';
class BoardMeatsController extends Controller {
}
class default_1 extends Controller {
    connect() {
        this.fetcher = new MutexFetcher();
    }
    create() {
        var _a;
        const url = this.data.get('url');
        if (url === null) {
            throw new Error('No URL found');
        }
        this.fetcher.call({
            url,
            method: 'POST',
            callback: (html) => {
                this.selfTarget.outerHTML = html;
                enableTooltip();
            },
            body: { quote: ((_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) || '' }
        });
    }
    destroy() {
        const url = this.data.get('url');
        if (url === null) {
            throw new Error('No URL found');
        }
        this.fetcher.call({
            url,
            method: 'DELETE',
            callback: (html) => {
                this.selfTarget.outerHTML = html;
                enableTooltip();
            }
        });
    }
    preventUnselectOnCreateMeat(e) {
        e.preventDefault();
    }
}
default_1.targets = ['self'];
export default default_1;
