import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
class TimelineController extends Controller {
}
class default_1 extends Controller {
    paging() {
        const page = this.data.get('page');
        const nextPage = parseInt(page, 10) + 1;
        const html = document.querySelector('html');
        this.pagingButtonTarget.classList.add('disabled');
        html.style.cursor = 'progress';
        fetch(Routes.api_timeline_path({ page: nextPage }), { credentials: 'include' })
            .then(response => {
            if (response.headers.has('X-Savanna-Timeline-Last-Page')) {
                this.pagingButtonTarget.style.display = 'none';
            }
            return response.text();
        })
            .then(html => {
            this.timelineTarget.insertAdjacentHTML('beforeend', html);
            this.data.set('page', nextPage.toString());
        })
            .finally(() => {
            html.style.cursor = 'auto';
            this.pagingButtonTarget.classList.remove('disabled');
        });
    }
}
default_1.targets = ['timeline', 'pagingButton'];
export default default_1;
