import { Controller } from '@hotwired/stimulus';
import { RailsFetcher } from 'api/rails_fetcher';
import Routes from 'common/rails_routes';
import { Turbo } from '@hotwired/turbo-rails';
export class DailyReportIndexController extends Controller {
}
const modifyLinkNotToPropagateEventsToParent = (elem) => {
    const originalHandler = elem.onclick;
    elem.onclick = (e) => {
        if ((e === null || e === void 0 ? void 0 : e.button) !== 0)
            return;
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        originalHandler === null || originalHandler === void 0 ? void 0 : originalHandler.apply(elem, [e]);
    };
};
const updateLinkWrappers = (root) => {
    root.querySelectorAll('a').forEach((elem) => {
        modifyLinkNotToPropagateEventsToParent(elem);
    });
    root.querySelectorAll('span.favorite-meats__count').forEach((elem) => {
        modifyLinkNotToPropagateEventsToParent(elem);
    });
    const modal = root.querySelector('#meatgazersModal');
    if (modal === null) {
        throw new Error('No modal found');
    }
    modifyLinkNotToPropagateEventsToParent(modal);
};
class default_1 extends Controller {
    connect() {
        const watchRoot = document.querySelector('.daily_reports');
        updateLinkWrappers(watchRoot);
        // meat_gazerのようにコントローラーがロードされた後で追加されるaタグについても
        // onclick handlerを上書きするためにMutationObserverを利用している。
        this.observer = new MutationObserver(() => {
            updateLinkWrappers(watchRoot);
        });
        this.observer.observe(watchRoot, { childList: true, subtree: true });
    }
    disconnect() {
        this.observer.disconnect();
    }
    visit() {
        Turbo.visit(this.urlValue);
    }
    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!confirm('削除しますか？')) {
            return;
        }
        const target = e.currentTarget;
        const url = target.dataset.url;
        if (url === undefined) {
            throw new Error('No URL found');
        }
        RailsFetcher.call({
            url,
            method: 'DELETE'
        }).then((response) => {
            if (response.ok) {
                Turbo.visit(Routes.daily_reports_path(), { action: 'replace' });
            }
            else {
                // TODO: ユーザーに分かるようにエラーメッセージ出したい
                console.debug('Unexpected status: ' + response.statusText);
            }
        });
    }
}
default_1.values = { url: String };
export default default_1;
