function buildFormValueSnapshot(form) {
    const mapping = new Map();
    for (const elem of form.querySelectorAll('input')) {
        const input = elem;
        if (input.name === 'authenticity_token')
            continue;
        switch (input.type) {
            case 'radio':
                if (!input.checked)
                    continue;
                break;
        }
        if (mapping.has(input.name))
            continue;
        mapping.set(input.name, input.value);
    }
    for (const elem of form.querySelectorAll('textarea')) {
        const input = elem;
        if (mapping.has(input.name))
            continue;
        mapping.set(input.name, input.value);
    }
    return mapping;
}
function detectFormModification(confirmable) {
    const originalValues = confirmable.originalValues;
    const currentValues = confirmable.currentValues;
    // お仕事状況の「いま仕事の話を振っても大丈夫？」のように初期値無し
    // radioボタンではbuildFormValueSnapshotで差分が記録されないため
    if (originalValues.size !== currentValues.size)
        return true;
    return Array.from(originalValues.keys()).some((key) => {
        return originalValues.get(key) !== currentValues.get(key);
    });
}
function requireConfirmation(list) {
    return list.some((confirmable) => {
        if (detectFormModification(confirmable)) {
            return true;
        }
        return false;
    });
}
let showDialogFlag = true;
let confirmableForms = [];
const dialogHandler = (event) => {
    if (!showDialogFlag)
        return;
    if (requireConfirmation(confirmableForms)) {
        const result = window.confirm('保存されていない変更があります。移動してよろしいですか？');
        event.returnValue = result;
    }
};
const dialogEnabler = () => {
    showDialogFlag = true;
};
// submit時に200系のレスポンスが返ってきたらダイアログ表示フラグを無効化する
const dialogDisabler = (e) => {
    const event = e;
    if (event.detail.success) {
        showDialogFlag = false;
    }
};
function isConfirmableForm(value) {
    return value !== undefined;
}
export const ConfirmableEvent = {
    reset: 'confirmable:reset'
};
export function Confirmable(Base) {
    return class extends Base {
        initConfirmableForms(formIdList) {
            this.formIdList = formIdList;
            const newForms = formIdList.map((formId) => {
                const form = document.getElementById(formId);
                if (form === null)
                    return undefined;
                const snapshot = buildFormValueSnapshot(form);
                if (snapshot === undefined)
                    return undefined;
                const confirmable = {
                    htmlId: formId,
                    originalValues: snapshot,
                    currentValues: snapshot
                };
                form.addEventListener('change', () => {
                    confirmable.currentValues = buildFormValueSnapshot(form);
                });
                form.addEventListener(ConfirmableEvent.reset, () => {
                    confirmable.originalValues = buildFormValueSnapshot(form);
                    confirmable.currentValues = buildFormValueSnapshot(form);
                });
                return confirmable;
            }).filter(isConfirmableForm);
            confirmableForms = confirmableForms.concat(newForms);
            window.addEventListener('turbo:submit-end', dialogDisabler);
            window.addEventListener('turbo:before-visit', dialogHandler);
            window.addEventListener('beforeunload', dialogHandler);
        }
        disconnect() {
            window.removeEventListener('beforeunload', dialogHandler);
            window.removeEventListener('turbo:before-visit', dialogHandler);
            window.removeEventListener('turbo:submit-end', dialogDisabler);
            dialogEnabler();
            this.formIdList.forEach((formId) => {
                const indexToRemove = confirmableForms.findIndex((form) => form.htmlId === formId);
                if (indexToRemove === -1)
                    return;
                confirmableForms = [...confirmableForms.slice(0, indexToRemove)];
            });
        }
    };
}
