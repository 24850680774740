import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { MutexFetcher } from 'api/mutex_fetcher';
class RoomSearchController extends Controller {
}
class default_1 extends Controller {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.throttlingSearch = false;
    }
    connect() {
        this.fetcher = new MutexFetcher();
    }
    search(e) {
        e.preventDefault();
        if (this.throttlingSearch)
            return;
        this.throttlingSearch = true;
        const self = this;
        setTimeout(function () {
            self.throttlingSearch = false;
            self.doSearch();
        }, 1000);
    }
    doSearch() {
        this.loading = true;
        const spinner = this.spinnerTarget;
        spinner.classList.remove('d-none');
        this.roomsTarget.innerHTML = '<div></div>';
        const url = Routes.api_rooms_path({ name_query: this.searchTextTarget.value });
        this.fetcher.call({
            url,
            method: 'GET',
            callback: (html) => {
                spinner.classList.add('d-none');
                this.roomsTarget.insertAdjacentHTML('afterbegin', html);
                this.loading = false;
            }
        });
    }
}
default_1.targets = ['searchText', 'spinner', 'rooms'];
export default default_1;
