import { Modal } from 'bootstrap';
document.addEventListener('keydown', (e) => {
    if (e.key !== '?') {
        return;
    }
    const inputs = ['input', 'select', 'button', 'textarea'];
    if (document.activeElement && inputs.indexOf(document.activeElement.tagName.toLowerCase()) !== -1) {
        return;
    }
    const help = document.getElementById('helpModal');
    if (help !== null) {
        new Modal(help).show();
    }
});
