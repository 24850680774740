const CharCounter = (input, label, max) => {
    return () => {
        const count = input.value.length;
        const alertClass = 'savanna-editor__character-count--alert';
        label.innerText = `${count}/${max}`;
        if (count > max) {
            label.classList.add(alertClass);
        }
        else {
            label.classList.remove(alertClass);
        }
    };
};
export default CharCounter;
