import { RailsFetcher } from 'api/rails_fetcher';
export class MutexFetcher {
    constructor() {
        this.locked = false;
    }
    call({ url, method, callback, error, body }) {
        if (this.locked) {
            return;
        }
        let errorCallback;
        if (error) {
            errorCallback = error;
        }
        else {
            errorCallback = (e) => { console.error(e); };
        }
        this.locked = true;
        RailsFetcher.call({
            url,
            method,
            body
        }).then((response) => {
            if (!response.ok) {
                throw Error("response isn't ok");
            }
            return response;
        })
            .then((response) => response.text())
            .then(callback)
            .catch(errorCallback)
            .finally(() => {
            this.locked = false;
        });
    }
}
