const longTextLength = 55;
function addMarkerToRegistry({ registry, element, lineno, editorStats }) {
    const bodyScrollTop = editorStats.lineToScrollTopMapping.get(lineno);
    const previewScrollTop = element.offsetTop;
    if (bodyScrollTop === undefined) {
        throw new Error('No bodyScrollTop found');
    }
    registry.set(bodyScrollTop, {
        line: lineno,
        node: element,
        bodyScrollTop,
        previewScrollTop
    });
}
function isAlreadyMarked(element, registry) {
    return Array.from(registry.values()).some((v) => v.node === element);
}
export const markerDetectors = [
    {
        type: 'header',
        finder: (editorStats, markdown, preview, registry) => {
            // 以下の対応関係を見つけている
            // ### foo => <hN>foo</hN> (N=1..6)
            const regex = /^#+\s+.+$/;
            const bodyLines = markdown.split('\n');
            const candidates = Array.from(preview.querySelectorAll('h1,h2,h3,h4,h5,h6'));
            bodyLines.forEach((line, idx) => {
                const lineno = idx + 1;
                const matched = line.match(regex);
                if (matched === null)
                    return;
                const positions = Array.from(registry.values());
                const [headerLevel, title] = matched[0].split(/\s+/);
                const tag = 'h' + headerLevel.length;
                const found = candidates.find((candidate) => {
                    if (positions.some((position) => position.node === candidate))
                        return false;
                    return candidate.outerHTML === `<${tag}>${title}</${tag}>`;
                });
                if (found === undefined) {
                    // バグ発生時だけでなくAPIレスポンス取得中などエディタとプレビューの内容が一致しない場合にも暫定的に発生する
                    // console.debug('corresponding header not found', matched[0])
                    return;
                }
                addMarkerToRegistry({
                    registry,
                    element: found,
                    lineno,
                    editorStats
                });
            });
        }
    },
    {
        type: 'image',
        finder: (editorStats, markdown, preview, registry) => {
            // 以下の対応関係を見つけている
            // ![image name](url) => <img src="url" alt="image name">
            const regex = /!\[(.+)\]\((.+)\)/;
            const bodyLines = markdown.split('\n');
            bodyLines.forEach((line, lineno) => {
                const matched = line.match(regex);
                if (matched === null)
                    return;
                const positions = Array.from(registry.values());
                const candidates = Array.from(preview.querySelectorAll(`img[alt="${matched[1]}"][src="${matched[2]}"]`));
                const found = candidates.find((candidate) => {
                    return !positions.some((position) => position.node === candidate);
                });
                if (found === undefined) {
                    // バグ発生時だけでなくAPIレスポンス取得中などエディタとプレビューの内容が一致しない場合にも暫定的に発生する
                    // console.debug('corresponding image not found', matched)
                    return;
                }
                addMarkerToRegistry({
                    registry,
                    element: found,
                    lineno,
                    editorStats
                });
            });
        }
    },
    {
        type: 'tweet',
        finder: (editorStats, markdown, preview, registry) => {
            // 以下の対応関係を見つけている
            // https://twitter.com/USER/status/TIMESTAMP => <div class="twitter-tweet twitter-tweet-rendered">...</div>
            //
            // NOTE: Twitterユーザー名のルールは以下を参照
            //       https://help.twitter.com/ja/managing-your-account/twitter-username-rules
            const regex = /^https:\/\/twitter\.com\/[a-zA-Z0-9_]+\/status\/([0-9]+)$/;
            const bodyLines = markdown.split('\n');
            const candidates = Array.from(preview.querySelectorAll('.twitter-tweet'));
            bodyLines.forEach((line, lineno) => {
                const matched = line.match(regex);
                if (matched === null)
                    return;
                const tweetUrl = matched[0];
                const tweetId = matched[1];
                const found = candidates.find((candidate) => {
                    // NOTE: twitterのwidgets.jsによるツイート埋め込みが行われる前後で対応するDOM要素の構成が変わる
                    if (candidate.querySelector(`a[href*="${tweetUrl}"], iframe[src*="${tweetId}"]`) === null)
                        return false;
                    return !isAlreadyMarked(candidate, registry);
                });
                if (found === undefined) {
                    // バグ発生時だけでなくAPIレスポンス取得中などエディタとプレビューの内容が一致しない場合にも暫定的に発生する
                    // console.debug('corresponding tweet not found', matched)
                    return;
                }
                addMarkerToRegistry({
                    registry,
                    element: found,
                    lineno,
                    editorStats
                });
            });
        }
    },
    {
        type: 'long_text',
        finder: (editorStats, markdown, preview, registry) => {
            // soft wrapのテキストエリアでは長文が自動でwrapされてしまい。スクロール幅の計算に影響する。
            // しかし、textarea内で任意の行にあるテキストが最終的にどのくらいのheightを持つか、各文字の
            // widthがいくつになるかを取得できるAPIは無い。よってヒューリスティックに55文字以上の文章を
            // long_textと分類してmarkerに指定する。
            const bodyLines = markdown.split('\n');
            const candidates = Array.from(preview.querySelectorAll('p'));
            bodyLines.forEach((line, lineno) => {
                if (line.length < longTextLength)
                    return;
                const found = candidates.find((candidate) => {
                    if (candidate.childElementCount > 0)
                        return false;
                    if (candidate.textContent !== line)
                        return false;
                    return !isAlreadyMarked(candidate, registry);
                });
                if (found === undefined) {
                    // バグ発生時だけでなくAPIレスポンス取得中などエディタとプレビューの内容が一致しない場合にも暫定的に発生する
                    // console.debug('corresponding long_text not found', line)
                    return;
                }
                addMarkerToRegistry({
                    registry,
                    element: found,
                    lineno,
                    editorStats
                });
            });
        }
    }
];
