import { Tooltip, Popover } from 'bootstrap';
export function enableTooltip() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });
}
export function enablePopover() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl);
    });
}
