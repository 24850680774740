// local-timeのi18nテンプレートをJSに変換して利用
// https://github.com/basecamp/local_time/blob/master/lib/assets/javascripts/src/local-time/config/i18n.coffee
//
// 翻訳のほとんどはrails-i18nからのコピー
// https://github.com/svenfuchs/rails-i18n/blob/master/rails/locale/ja.yml
import LocalTime from 'local-time';
LocalTime.config.i18n.ja = {
    date: {
        dayNames: [
            '日曜日',
            '月曜日',
            '火曜日',
            '水曜日',
            '木曜日',
            '金曜日',
            '土曜日'
        ],
        abbrDayNames: [
            '日',
            '月',
            '火',
            '水',
            '木',
            '金',
            '土'
        ],
        monthNames: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        abbrMonthNames: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
        ],
        yesterday: '昨日',
        today: '今日',
        tomorrow: '明日',
        on: '{date}',
        formats: {
            default: '%Y/%m/%d',
            thisYear: '%m/%d'
        }
    },
    time: {
        am: 'am',
        pm: 'pm',
        singular: '約1{time}',
        singularAn: '約1{time}',
        elapsed: '{time}前',
        second: '秒',
        seconds: '秒',
        minute: '分',
        minutes: '分',
        hour: '時間',
        hours: '時間',
        formats: {
            default: '%H:%M'
        }
    },
    datetime: {
        at: '{date} {time}',
        formats: { default: '%Y/%m/%d %H:%M' }
    }
};
LocalTime.config.locale = 'ja';
LocalTime.start();
