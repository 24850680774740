const decorateMessageHtml = (elem, receiverId) => {
    const containers = elem.querySelectorAll('.new-message-content__container');
    if (containers.length === 0)
        return;
    Array.prototype.forEach.call(containers, (container) => {
        const senderIdString = container.dataset.senderId;
        if (senderIdString === undefined) {
            throw new Error('No senderId in dataset');
        }
        const senderId = parseInt(senderIdString, 10);
        if (senderId === receiverId) {
            container.classList.add('me');
        }
    });
};
export default decorateMessageHtml;
