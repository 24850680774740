import { applyEmojiAutoComplete } from 'emoji';
let observer;
const textcompleteList = [];
document.addEventListener('turbo:load', () => {
    if (observer !== undefined) {
        return;
    }
    // textcompleteList.forEach((obj) => obj.destroy())
    const textareaElements = document.getElementsByTagName('textarea');
    if (textareaElements.length !== 0) {
        applyEmojiAutoComplete(Array.from(textareaElements), textcompleteList);
    }
    const mutationCallback = (mutationList) => {
        mutationList.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const elements = node.getElementsByTagName('textarea');
                    if (elements.length !== 0) {
                        applyEmojiAutoComplete(Array.from(elements), textcompleteList);
                    }
                }
            });
        });
    };
    observer = new MutationObserver(mutationCallback);
    observer.observe(document, { childList: true, subtree: true });
});
