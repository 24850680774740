import { Controller } from '@hotwired/stimulus';
import Routes from 'common/rails_routes';
import { RailsFetcher } from 'api/rails_fetcher';
class MaybeFriendsController extends Controller {
}
class default_1 extends Controller {
    exclude(event) {
        const target = event.currentTarget;
        const userId = target.dataset.userId;
        RailsFetcher.call({
            url: Routes.unsuggested_friendships_path(),
            method: 'POST',
            body: { user_id: userId }
        }).then(
        // エラーが発生する可能性は非常に低いので処理しない
        // See https://github.com/willnet-inc/savanna/pull/2337#issuecomment-608279097
        response => response.text())
            .then(partial => {
            target.remove();
            this.usersTarget.innerHTML = partial;
        });
    }
}
default_1.targets = ['users'];
export default default_1;
